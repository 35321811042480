* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  box-shadow: none;
  &::before,
  &::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
}
*:focus {
  outline: none;
}
::selection {
  background-color: #ff2d55;
  color: #fff;
}
body,
html {
  margin: 0;
  padding: 0;
  font-family: "Graphik", sans-serif;
  font-weight: normal;
  height: 100%;
  font-size: 12px;
  @media (min-width: 768px) {
    font-size: 16px;
  }
}
h1,
h2,
h3,
h4 {
  font-weight: bold;
  margin: 0;
}
ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
  color: inherit;
}

body {
  font-family: "Graphik";
  background: #f0f0f0;
}
.w100 {
  width: 100%;
}

.block-for-mobile {
  display: block;
  @media (min-width: 768px) {
    display: none;
  }
}

.block-for-tablet {
  display: block;
  @media (min-width: 992px) {
    display: none;
  }
}

.block-not-for-tablet {
  display: none;
  @media (min-width: 1024px) {
    display: block;
  }
}

.block-for-desktop {
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
}

.error {
  color: #ff4016;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.img {
  max-width: 100%;
}

.medium {
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-underline {
  text-decoration: underline;
}

.simple-react-notifier {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99999;
  pointer-events: none;
  padding: 16px;
  padding-right: 18px;
  .item {
    justify-content: space-between;
  }
}

.input-disable {
  pointer-events: none;
}

.countBox {
  display: flex;
  flex-direction: row-reverse;
  &Item {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 0.3rem;
    align-items: flex-end;
    .label {
      font-size: 18px !important;
      text-transform: lowercase;
    }
  }
}

.custom-scroll {
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #eceeef;
  }

  &::-webkit-scrollbar {
    width: 5px;
    max-height: 5px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #c7c7c7;
  }
}

body {
  .Dropdown-root {
    min-width: 150px;
  }
  .Dropdown-control,
  .Dropdown-option {
    border: none;
    background: #f0f0f0;
    font-size: 1rem;
    font-weight: 500;
  }
  .Dropdown-option {
    color: black;
    &:hover,
    &.is-selected {
      background: #fff;
    }
  }
  .Dropdown-menu {
    border: none;
  }
}

.react-tooltip-lite {
  width: initial !important;
  padding: 0px !important;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.19);
}

.react-tooltip-lite-arrow {
  border-color: #fff;
}
.react-tooltip-clickable-link {
  pointer-events: none;
}

.mr--3 {
  margin-right: -0.75rem;
}

.circleHover {
  &:hover {
    circle {
      fill: red;
    }
  }
}

.pathHover {
  &:hover {
    path {
      fill: red;
    }
  }
}
